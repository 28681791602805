import IDW from 'ol-ext/source/IDW';
import ImageLayer from 'ol/layer/Image';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import View from 'ol/View';
import datasourceList from './datasource-list';
import meteoLegends from './layers/meteo-legends';
import {FieAnalysisService} from './analysis/analysis.service';
import {Geometry} from 'ol/geom';
import {
  HsConfig,
  HsLanguageService,
  HsPanelContainerService,
  HsShareUrlService,
} from 'hslayers-ng';
import {HsSensorsComponent, HsSensorsService} from 'hslayers-sensors';
import {HsToastService} from 'hslayers-ng';
import {Injectable} from '@angular/core';
import {InterpolatedSource} from 'hslayers-ng';
import {Style} from 'ol/style';
import {analysesCsTranslations} from './analysis/translations.cs';
import {analysesEnTranslations} from './analysis/translations.en';
import {analysesLvTranslations} from './analysis/translations.lv';
import {default_layers, setupLayers} from './layers/layers';
import {environment} from '../environments/environment';
import {translationOverrides} from './translations';

@Injectable({providedIn: 'root'})
export class MainService {
  assetsPath: string;
  constructor(
    private hsPanelContainerService: HsPanelContainerService,
    private HsConfig: HsConfig,
    private FieAnalysisService: FieAnalysisService,
    private HsLanguageService: HsLanguageService,
    private HsShareUrlService: HsShareUrlService,
    private HsToastService: HsToastService,
    private HsSensorsService: HsSensorsService
  ) {
    window.document.title = environment.title;
  }

  async init(): Promise<void> {
    this.hsPanelContainerService.create(HsSensorsComponent, {
      viewMode: 'sensornames',
    });

    this.assetsPath = 'assets/';
    const interpolatedSource = new InterpolatedSource({
      maxFeaturesInCache: 500,
      maxFeaturesInExtent: 100,
      features: [],
      weight: 'Leaf wetness (10 cm)',
      loader: async ({extent, projection}) => {
        return (
          this.HsSensorsService.get(
            'default'
          ).layer.getSource() as VectorSource<Geometry>
        ).getFeatures();
      },
      colorMap: 'jet',
    });
    const idwLayer = new ImageLayer({
      properties: {title: 'Leaf wetness (10 cm) interpolated'},
      source: interpolatedSource as any,
      opacity: 0.5,
    });

    //Mandatory, otherwise nothing will be loaded with source loader
    const idwVectorLayer = new VectorLayer({
      properties: {
        showInLayerManager: false,
        visible: idwLayer.getVisible(),
      },
      style: new Style(),
      source: (interpolatedSource as IDW).getSource(),
    });
    this.HsConfig.update({
      assetsPath: this.assetsPath + 'hslayers-ng',
      proxyPrefix: '/proxy/',
      searchProvider: (q) => {
        return `/proxy/search/?q=${q}`;
      },
      default_layers: [...default_layers/*, idwLayer, idwVectorLayer*/],
      //layersInFeatureTable: [aoiLayer],
      project_name: 'erra/map',
      open_lm_after_comp_loaded: true,
      default_view: new View({
        center: environment.viewCenter,
        zoom: environment.viewZoom,
      }),
      popUpDisplay: 'click',
      advancedForm: true,
      datasources: datasourceList,
      panelWidths: {
        sensors: 440,
      },
      sidebarPosition: 'left',
      panelsEnabled: {
        language: true,
        compositionLoadingProgress: false,
        feature_crossfilter: false,
        mapSwipe: true,
      },
      componentsEnabled: {
        basemapGallery: false,
        mapSwipe: false,
      },
      senslog: {
        liteApiPath: 'senslogOT',
        senslog1Path: 'senslog1O',
        url: 'https://sensor.lesprojekt.cz',
        user_id: environment.user_id, //Needed for senslogOT
        group: environment.group, //Needed for MapLogOT
        user: environment.user, //Needed for MapLogOT
      },
      translationOverrides,
      layerTooltipDelay: 1500,
      saveMapStateOnReload: false,
      timeDisplayFormat: 'dd.MM.yyyy',
      //,datasource_selector: {allow_add: false}
    });

    const confRef = this.HsConfig.get();
    confRef.defaultDrawLayerPath = 'User generated';
    Object.assign(confRef.translationOverrides.lv, analysesLvTranslations);
    Object.assign(confRef.translationOverrides.en, analysesEnTranslations);
    Object.assign(confRef.translationOverrides.cs, analysesCsTranslations);
    setupLayers(
      this.HsLanguageService,
      this.HsShareUrlService,
      this.FieAnalysisService,
      this.HsToastService
    );
    meteoLegends(confRef.default_layers);
    //this.HsDrawService.selectLayer(aoiLayer);
  }

  getHostname() {
    const url = window.location.href;
    const urlArr = url.split('/');
    const domain = urlArr[2];
    return urlArr[0] + '//' + domain;
  }
}
